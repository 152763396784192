const routes = [
    {
        path: '',
        redirect: '/analysis'
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue'),
        meta: {
            title: 'test',
            requireAuth: true
        }
    },
    {
        name: 'account',
        path: '/account',
        component: () => import('@/pages/account.vue'),
        meta: {
            title: '用户管理',
            requireAuth: true
        }
    },
    {
        name: 'fahuoshuju',
        path: '/fahuohuikuan/fahuoshuju',
        component: () => import('@/pages/fahuohuikuan/fahuoshuju.vue'),
        meta: {
            title: '发货数据',
            requireAuth: true
        }
    },
    {
        name: 'huikuanshuju',
        path: '/fahuohuikuan/huikuanshuju',
        component: () => import('@/pages/fahuohuikuan/huikuanshuju.vue'),
        meta: {
            title: '回款数据',
            requireAuth: true
        }
    },
    {
        name: 'fahuohuikuanpipei',
        path: '/fahuohuikuan/fahuohuikuanpipei',
        component: () => import('@/pages/fahuohuikuan/fahuohuikuanpipei.vue'),
        meta: {
            title: '发货回款匹配',
            requireAuth: true
        }
    },
    {
        name: 'huikuanshuju_pipei_success',
        path: '/fahuohuikuan/huikuanshuju_pipei_success',
        component: () => import('@/pages/fahuohuikuan/huikuanshuju_pipei_success.vue'),
        meta: {
            title: '回款匹配成功',
            requireAuth: true
        }
    },
    {
        name: 'fahuoshuju_pipei_success',
        path: '/fahuohuikuan/fahuoshuju_pipei_success',
        component: () => import('@/pages/fahuohuikuan/fahuoshuju_pipei_success.vue'),
        meta: {
            title: '发货匹配成功',
            requireAuth: true
        }
    },
    {
        name: 'analysis',
        path: '/analysis',
        component: () => import('@/pages/analysis.vue'),
        meta: {
            title: '数据看板',
            requireAuth: true
        }
    },
    {
        name: 'gongsijiesuanzhengce',
        path: '/shujubeian/gongsijiesuanzhengce',
        component: () => import('@/pages/shujubeian/gongsijiesuanzhengce.vue'),
        meta: {
            title: '公司结算政策',
            requireAuth: true
        }
    },
    {
        name: 'bumenyuliuzhengce',
        path: '/shujubeian/bumenyuliuzhengce',
        component: () => import('@/pages/shujubeian/bumenyuliuzhengce.vue'),
        meta: {
            title: '部门预留政策',
            requireAuth: true
        }
    },
    {
        name: 'jiesuanzhengce',
        path: '/shujubeian/jiesuanzhengce',
        component: () => import('@/pages/shujubeian/jiesuanzhengce.vue'),
        meta: {
            title: '结算备案',
            requireAuth: true
        }
    }
];
export default routes